<template>
  <v-app>
    <loading :active.sync="is_call_api"></loading>
    <div style="background-color: rgb(243 246 249)">
      <h1 class="mt-5 mb-5" style="color: #3f4254">
        Grading Scale {{ grading_scale_title }} Calculate Methods Management
      </h1>
    </div>

    <div class="pt-5 pl-5 pr-5">
      <v-btn
        rounded
        color="primary"
        class="mb-5"
        x-small
        large
        @click="openDiaLogGradingScale"
      >
        <v-icon color="white" dark>mdi-plus</v-icon
        ><span style="color: white">Thêm kĩ năng</span>
      </v-btn>
      <div>
        <v-row>
          <v-col cols="12">
            <div class="table-responsive">
              <table
                class="table datatable-bordered table-head-custom table-vertical-center table-head-bg"
              >
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Name</th>
                    <th scope="col">Calculate Method</th>
                    <th scope="col">Score</th>
                    <th scope="col">Step Value</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <template
                    v-for="(grading_scale_skill, i) in grading_scale_skills"
                  >
                    <tr :key="i">
                      <td scope="row">{{ i + 1 }}</td>
                      <td>
                        <p
                          class="text-dark-75 font-weight-bolder d-block font-size-lg"
                        >
                          {{ grading_scale_skill.skill_label }}
                        </p>
                      </td>
                      <td>
                        <p
                          class="text-dark-75 font-weight-bolder d-block font-size-lg"
                        >
                          {{ grading_scale_skill.method_type_label }}
                        </p>
                      </td>
                      <td>
                        <p
                          class="text-dark-75 font-weight-bolder d-block font-size-lg"
                        >
                          Range of score: {{ grading_scale_skill.min_score }} -
                          {{ grading_scale_skill.max_score }}
                        </p>
                      </td>
                      <td>
                        <p
                          class="text-dark-75 font-weight-bolder d-block font-size-lg"
                        >
                          {{ grading_scale_skill.step_value }}
                        </p>
                      </td>
                      <td>
                        <div style="display: flex">
                          <v-tooltip left>
                            <template v-slot:activator="{ on, attrs }">
                              <button
                                class="btn btn-icon btn-light-success btn-sm"
                                v-bind="attrs"
                                v-on="on"
                                @click="
                                  editGradingScaleSkill(
                                    i,
                                    grading_scale_skill.id
                                  )
                                "
                              >
                                <span
                                  class="svg-icon svg-icon-md svg-icon-primary"
                                >
                                  <v-icon color=""
                                    >mdi-square-edit-outline</v-icon
                                  >
                                </span>
                              </button>
                            </template>
                            <span>Edit</span>
                          </v-tooltip>
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <button
                                class="btn btn-icon btn-light-danger btn-sm ml-4"
                                v-bind="attrs"
                                v-on="on"
                                @click="
                                  deleteScaleSkill(grading_scale_skill.id)
                                "
                              >
                                <span
                                  class="svg-icon svg-icon-md svg-icon-primary"
                                >
                                  <v-icon color="">mdi-delete</v-icon>
                                </span>
                              </button>
                            </template>
                            <span>Delete</span>
                          </v-tooltip>
                          <v-tooltip
                            top
                            v-if="grading_scale_skill.method_type == 3"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <button
                                class="btn btn-icon btn-light-primary btn-sm ml-4"
                                v-bind="attrs"
                                v-on="on"
                                @click="
                                  openDialogDetail(grading_scale_skill.id)
                                "
                              >
                                <span
                                  class="svg-icon svg-icon-md svg-icon-primary"
                                >
                                  <v-icon color=""
                                    >mdi-format-list-numbered</v-icon
                                  >
                                </span>
                              </button>
                            </template>
                            <span>Calculate Detail</span>
                          </v-tooltip>
                          <v-tooltip
                            top
                            v-if="grading_scale_skill.has_marking_criteria == 1"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <button
                                class="btn btn-icon btn-light-warning btn-sm ml-4"
                                v-bind="attrs"
                                v-on="on"
                                @click="
                                  openDialogMarkingCriterial(
                                    grading_scale_skill.id
                                  )
                                "
                              >
                                <span
                                  class="svg-icon svg-icon-md svg-icon-primary"
                                >
                                  <v-icon color=""
                                    >mdi-view-list-outline</v-icon
                                  >
                                </span>
                              </button>
                            </template>
                            <span>Marking Criterial</span>
                          </v-tooltip>
                        </div>
                      </td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
          </v-col>
        </v-row>
      </div>
    </div>

    <v-row justify="center">
      <v-dialog
        v-model="dialogGradingScaleSkill"
        max-width="1000px"
        scrollable
        persistent
      >
        <v-card>
          <v-card-title>
            <v-col cols="11">
              <span v-if="type_form_scale_skill == 'add'" class="headline"
                >Add Skill</span
              >
              <span v-if="type_form_scale_skill == 'update'" class="headline"
                >Edit Skill</span
              >
            </v-col>
            <v-col cols="1">
              <v-icon @click="dialogGradingScaleSkill = false" right
                >mdi-close</v-icon
              >
            </v-col>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" class="pt-0">
                  <v-row>
                    <v-col cols="3">
                      <label
                        style="font-size: 16px"
                        class="mt-1 p-0 float-right"
                        >Skill:
                      </label>
                    </v-col>
                    <v-col cols="6">
                      <v-select
                        :items="skills"
                        item-text="name"
                        item-value="id"
                        v-model="skill_input"
                        outlined
                      ></v-select>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" class="pt-0">
                  <v-row>
                    <v-col cols="3">
                      <label
                        style="font-size: 16px"
                        class="mt-1 p-0 float-right"
                        >Type:
                      </label>
                    </v-col>
                    <v-col cols="6">
                      <v-select
                        :items="types"
                        item-text="label"
                        item-value="value"
                        v-model="type_input"
                        outlined
                      ></v-select>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" class="pt-0">
                  <v-row>
                    <v-col cols="3">
                      <label
                        style="font-size: 16px"
                        class="mt-1 p-0 float-right"
                        >Score Per Correct Answer:
                      </label>
                    </v-col>
                    <v-col cols="6">
                      <v-text-field
                        v-model="score_input"
                        type="number"
                        outlined
                        required
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" class="pt-0">
                  <v-row>
                    <v-col cols="3">
                      <label
                        style="font-size: 16px"
                        class="mt-1 p-0 float-right"
                        >Min Score:
                      </label>
                    </v-col>
                    <v-col cols="6">
                      <v-text-field
                        v-model="min_score_input"
                        type="number"
                        outlined
                        required
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" class="pt-0">
                  <v-row>
                    <v-col cols="3">
                      <label
                        style="font-size: 16px"
                        class="mt-1 p-0 float-right"
                        >Max Score:
                      </label>
                    </v-col>
                    <v-col cols="6">
                      <v-text-field
                        v-model="max_score_input"
                        type="number"
                        outlined
                        required
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" class="pt-0">
                  <v-row>
                    <v-col cols="3">
                      <label
                        style="font-size: 16px"
                        class="mt-1 p-0 float-right"
                        >Step Value
                      </label>
                    </v-col>
                    <v-col cols="6">
                      <v-text-field
                        v-model="step_value_input"
                        type="number"
                        outlined
                        required
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" class="pt-0">
                  <v-row>
                    <v-col cols="3">
                      <label
                        style="font-size: 16px"
                        class="mt-1 p-0 float-right"
                        >Use Marking Criteria:
                      </label>
                    </v-col>
                    <v-col cols="1">
                      <v-checkbox
                        class="mt-0 pt-0"
                        v-model="is_marking_criteria_input"
                      ></v-checkbox>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" class="pt-0">
                  <v-row>
                    <v-col cols="3">
                      <label
                        style="font-size: 16px"
                        class="mt-1 p-0 float-right"
                        >Marking Criteria Calculate Method:
                      </label>
                    </v-col>
                    <v-col cols="6">
                      <v-select
                        :items="marking_criterias"
                        item-text="label"
                        item-value="value"
                        v-model="marking_criteria_input"
                        outlined
                      ></v-select>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <button
              class="btn btn-light-success text-uppercase mr-2"
              v-if="type_form_scale_skill == 'add'"
              @click="btnAddOrEditScaleSkill(1)"
            >
              Add
            </button>
            <button
              class="btn btn-light-success text-uppercase mr-2"
              v-if="type_form_scale_skill == 'update'"
              @click="btnAddOrEditScaleSkill(2)"
            >
              Update
            </button>
            <button
              class="btn text-uppercase mr-2"
              style="color: #f64e60"
              @click="dialogGradingScaleSkill = false"
            >
              Close
            </button>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>

    <SkillCalculateDetailModal
      :show_dialog="dialogScaleDetail"
      @setDialog="(e) => (dialogScaleDetail = e)"
      :grading_scale_id="grading_scale_id"
      :grading_scale_skill_id="grading_scale_skill_id"
    />
    <MarkingCriterial
      :show_dialog="dialogMarkingCriterial"
      @setDialog="(e) => (dialogMarkingCriterial = e)"
      :grading_scale_id="grading_scale_id"
      :grading_scale_skill_id="grading_scale_skill_id"
    />
  </v-app>
</template>

<script>
import "vue-loading-overlay/dist/vue-loading.css";
import ApiService from "@/service/api.service";
import Swal from "sweetalert2";
export default {
  name: "GradingScaleSkill",
  components: {
    Loading: () => import("vue-loading-overlay"),
    SkillCalculateDetailModal: () => import("./modal/SkillCalculateDetail"),
    MarkingCriterial: () => import("./modal/MarkingCriterial"),
  },
  data() {
    return {
      is_call_api: false,
      skills: [],
      grading_scale_skills: [],
      grading_scale_title: null,
      dialogGradingScaleSkill: false,
      type_form_scale_skill: null,
      types: [],
      marking_criterias: [],
      skill_input: null,
      type_input: null,
      score_input: null,
      step_value_input: null,
      min_score_input: null,
      max_score_input: null,
      is_marking_criteria_input: false,
      marking_criteria_input: null,
      grading_scale_skill_id: null,
      dialogScaleDetail: false,
      dialogMarkingCriterial: false,
    };
  },
  computed: {
    grading_scale_id: function () {
      return this.$route.params.id;
    },
  },
  created() {
    this.getAllSkill();
    this.getAllType();
    this.getAllMarkingCriteria();
    this.getAllGradingScaleSkill();
  },
  methods: {
    async getAllGradingScaleSkill() {
      let vm = this;
      try {
        vm.is_call_api = true;
        let res = await ApiService.get(
          "prep-app/grading-scale/" + this.grading_scale_id + "/skills"
        );
        if (res.status == 200) {
          vm.grading_scale_title = res.data.name;
          vm.grading_scale_skills = res.data.skills;
          vm.is_call_api = false;
        }
      } catch (error) {
        vm.is_call_api = false;
        console.log(error);
      }
    },
    async getAllSkill() {
      let vm = this;
      let res = await ApiService.get("prep-app/tag/list-skill");
      if (res.status == 200) {
        vm.skills = res.data;
      }
    },
    async getAllType() {
      let vm = this;
      this.types = [{ value: null, label: "" }];
      let res = await ApiService.get(
        "prep-app/grading-scale/list-type-calculate-method"
      );
      if (res.status == 200) {
        vm.types = [...vm.types, ...res.data];
      }
    },
    async getAllMarkingCriteria() {
      let vm = this;
      this.marking_criterias = [{ value: null, label: "" }];
      let res = await ApiService.get(
        "prep-app/grading-scale/list-marking-criteria-method"
      );
      if (res.status == 200) {
        vm.marking_criterias = [...vm.marking_criterias, ...res.data];
      }
    },
    openDiaLogGradingScale() {
      this.defaultData();
      this.type_form_scale_skill = "add";
      this.dialogGradingScaleSkill = true;
    },
    defaultData() {
      this.skill_input = null;
      this.type_input = null;
      this.score_input = null;
      this.min_score_input = null;
      this.max_score_input = null;
      this.is_marking_criteria_input = false;
      this.marking_criteria_input = null;
      this.step_value_input = null;
    },
    editGradingScaleSkill(i, grading_scale_skill_id) {
      let data = this.grading_scale_skills[i];
      this.skill_input = data.skill_id;
      this.type_input = data.method_type;
      this.score_input = data.score;
      this.min_score_input = data.min_score;
      this.max_score_input = data.max_score;
      this.is_marking_criteria_input =
        data.has_marking_criteria == 1 ? true : false;
      this.marking_criteria_input = data.marking_criteria_calculate_method;
      this.step_value_input = data.step_value;
      this.grading_scale_skill_id = grading_scale_skill_id;
      this.type_form_scale_skill = "update";
      this.dialogGradingScaleSkill = true;
    },
    btnAddOrEditScaleSkill(type) {
      let vm = this;
      let data = {
        skill_id: this.skill_input,
        method_type: this.type_input,
        score: this.score_input,
        min_score: this.min_score_input,
        max_score: this.max_score_input,
        step_value: this.step_value_input,
        has_marking_criteria: this.is_marking_criteria_input == true ? 1 : 0,
      };
      if (this.is_marking_criteria_input) {
        data.marking_criteria_calculate_method = this.marking_criteria_input;
      }
      if (type == 1) {
        ApiService.post(
          "prep-app/grading-scale/" + this.grading_scale_id + "/skills",
          data
        )
          .then(function (res) {
            if (res.status == 200) {
              vm.getAllGradingScaleSkill();
              Swal.fire({
                title: "",
                text: "The application has been successfully submitted!",
                icon: "success",
                confirmButtonClass: "btn btn-secondary",
              });
              vm.dialogGradingScaleSkill = false;
            }
          })
          .catch(function (error) {
            if (error.response.status == 422) {
              let data_response_error =
                error.response.data.error.message.split(".<br />");
              vm.showError(data_response_error);
            }
          });
      } else {
        data.scale_skill_id = this.grading_scale_skill_id;
        ApiService.post(
          "prep-app/grading-scale/" + this.grading_scale_id + "/skills/update",
          data
        )
          .then(function (res) {
            if (res.status == 202) {
              vm.getAllGradingScaleSkill();
              Swal.fire({
                title: "",
                text: "The application has been successfully submitted!",
                icon: "success",
                confirmButtonClass: "btn btn-secondary",
              });
              vm.dialogGradingScaleSkill = false;
            }
          })
          .catch(function (error) {
            if (error.response.status == 422) {
              let data_response_error =
                error.response.data.error.message.split(".<br />");
              vm.showError(data_response_error);
            }
          });
      }
    },
    showError(data_error) {
      for (let i = 0; i < data_error.length; i++) {
        this.$toasted.error(data_error[i] + " !!", {
          theme: "toasted-primary",
          position: "top-right",
          duration: 4000,
        });
      }
    },
    deleteScaleSkill(grading_scale_skill_id) {
      let vm = this;
      let data = {
        scale_skill_id: grading_scale_skill_id,
      };
      Swal.fire({
        title: "Are you sure you want to delete?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.value) {
          ApiService.post(
            "prep-app/grading-scale/" + vm.grading_scale_id + "/skills/delete",
            data
          ).then(function (res) {
            if (res.status == 200) {
              vm.getAllGradingScaleSkill();
            }
          });
        }
      });
    },
    openDialogDetail(grading_scale_skill_id) {
      this.grading_scale_skill_id = grading_scale_skill_id;
      this.dialogScaleDetail = true;
    },
    openDialogMarkingCriterial(grading_scale_skill_id) {
      this.grading_scale_skill_id = grading_scale_skill_id;
      this.dialogMarkingCriterial = true;
    },
  },
};
</script>

<style lang="css" scoped>
.table.table-head-bg thead th {
  background-color: #2196f3eb !important;
  color: white !important;
}
.label.label-light-success {
  color: #3699ff;
  background-color: #e1f0ff;
}
.btn.btn-light-success {
  color: #3699ff;
  background-color: #e1f0ff;
  border-color: transparent;
}
.btn.btn-light-success i {
  color: #3699ff;
}
.btn.btn-light-success:hover:not(.btn-text):not(:disabled):not(.disabled),
.btn.btn-light-success:focus:not(.btn-text),
.btn.btn-light-success.focus:not(.btn-text) {
  background-color: #3699ff;
}
</style>
